import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '@organisms/layout'
import Text from '@particles/text'
import Heading from '@particles/heading'
import Tags from '@molecules/tags'
import Formula from '@atoms/formula'
import CTA from '@molecules/cta'
import SEO from '@atoms/seo'
import { media } from '@breakpoints'
import MetricTemplate from '@molecules/metric-template'

const CompareRowGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  column-gap: 2rem;
  border-bottom: 1px solid #dfebfb;
  padding: 2rem 0;
  width: 100%;
  ${media.tablet`
    grid-template-columns: 1fr;
  `}
`

const CompareSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;

  ${media.tablet`
    overflow-x: auto;
    overflow-y: hidden;
             
  `}
  width: 100%;
`

const CompareCell = styled.div`
  min-width: 14rem;
`
const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' }

const ComparisonPage = ({ data, location }) => {
  const { comparison } = data
  if (!comparison || !comparison.metric_a || !comparison.metric_b)
    return <div />
  const metricA = comparison.metric_a
  const metricB = comparison.metric_b
  const hasMetricTemplates =
    metricA.instant_metrics?.length > 0 || metricB.instant_metrics?.length > 0 //TODO: Add metric template (metricA.relationships.field_metric_templates && metricA.relationships.field_metric_templates.length > 0) || (metricB.relationships.field_metric_templates && metricB.relationships.field_metric_templates.length > 0)

  if (!metricA || !metricB) return <div />
  return (
    <Layout location={location}>
      <SEO
        title={comparison.title}
        location={location}
        description={comparison.description}
      />
      <Text size={1.5}>What is the difference? </Text>
      <Heading margin="T1 B3">{comparison.title}</Heading>
      <CompareRowGrid
        style={{ borderBottom: '4px #485066 solid', paddingBottom: '1rem' }}
      >
        <span />
        {metricA.name && metricB.name && (
          <CompareSection>
            <CompareCell>
              <Text bold>{metricA.name}</Text>
            </CompareCell>
            <CompareCell>
              <Text bold>{metricB.name}</Text>
            </CompareCell>
          </CompareSection>
        )}
      </CompareRowGrid>
      <CompareRowGrid>
        <Heading h4 margin="B1">
          What is it?
        </Heading>
        {metricA.description && metricB.description && (
          <CompareSection>
            <CompareCell>
              <Text>{metricA.description}</Text>
              <CTA margin="T1" link={metricA.slug}>
                Read More
              </CTA>
            </CompareCell>
            <CompareCell>
              <Text>{metricB.description}</Text>
              <CTA margin="T1" link={metricB.slug}>
                Read More
              </CTA>
            </CompareCell>
          </CompareSection>
        )}
      </CompareRowGrid>
      <CompareRowGrid>
        <Heading h4 margin="B1">
          Who is it for?
        </Heading>
        <CompareSection>
          <CompareCell>
            <Tags
              wrap="left"
              solid="true"
              left={0}
              tags={metricA.role.map((val) => {
                return { name: val.name, link: val.slug }
              })}
            />
          </CompareCell>
          <CompareCell>
            <Tags
              wrap="left"
              solid={true}
              left={0}
              tags={metricB.role.map((val) => {
                return { name: val.name, link: val.slug }
              })}
            />
          </CompareCell>
        </CompareSection>
      </CompareRowGrid>
      <CompareRowGrid>
        <Heading h4 margin="B1">
          Categories
        </Heading>
        <CompareSection>
          <CompareCell>
            <Tags
              wrap="left"
              solid="true"
              left={0}
              tags={metricA.category.map((val) => {
                return { name: val.name, link: val.slug }
              })}
            />
          </CompareCell>
          <CompareCell>
            <Tags
              wrap="left"
              solid="true"
              left={0}
              tags={metricB.category.map((val) => {
                return { name: val.name, link: val.slug }
              })}
            />
          </CompareCell>
        </CompareSection>
      </CompareRowGrid>
      <CompareRowGrid>
        <Heading h4 margin="B1">
          Formula
        </Heading>
        {metricA.formulas && metricB.formulas && (
          <CompareSection>
            <CompareCell>
              {metricA.formulas.map(({ formula }, i) => (
                <Formula key={`formula-metric-a-${i}`}>{formula}</Formula>
              ))}
            </CompareCell>
            <CompareCell>
              {metricB.formulas.map(({ formula }, i) => (
                <Formula key={`formula-metric-b-${i}`}>{formula}</Formula>
              ))}
            </CompareCell>
          </CompareSection>
        )}
      </CompareRowGrid>
      {metricA.example && metricB.example && (
        <CompareRowGrid>
          <Heading h4 margin="B1">
            Example
          </Heading>
          <CompareSection>
            <CompareCell>
              <Text>{metricA.example}</Text>
            </CompareCell>
            <CompareCell>
              <Text>{metricB.example}</Text>
            </CompareCell>
          </CompareSection>
        </CompareRowGrid>
      )}
      {hasMetricTemplates && (
        <CompareRowGrid>
          <Heading h4 margin="B1">
            Track this metric
          </Heading>
          <CompareSection>
            <CompareCell>
              <Layout.Grid style={{ justifyContent: '1fr', rowGap: '1rem' }}>
                {metricA.instant_metrics.map((template, i) => (
                  <MetricTemplate //TODO: Templates
                    key={`template-${i}`}
                    service={template.service.name}
                    link={template.url}
                    pageSource={location.pathname}
                  />
                ))}
              </Layout.Grid>
            </CompareCell>
            <CompareCell>
              <Layout.Grid style={{ justifyContent: '1fr', rowGap: '1rem' }}>
                {metricB.instant_metrics.map((template, i) => (
                  <MetricTemplate //TODO: Templates
                    key={`template-${i}`}
                    service={template.service.name}
                    link={template.url}
                    pageSource={location.pathname}
                  />
                ))}
              </Layout.Grid>
            </CompareCell>
          </CompareSection>
        </CompareRowGrid>
      )}
      <CompareRowGrid>
        <Heading h4 margin="B1">
          Published and updated dates
        </Heading>
        <CompareSection>
          <CompareCell>
            <Text>
              Date created:{' '}
              {new Date(metricA.date_created).toLocaleDateString(
                undefined,
                dateOptions
              )}
            </Text>
            <Text>
              Latest update:{' '}
              {new Date(metricA.date_updated).toLocaleDateString(
                undefined,
                dateOptions
              )}
            </Text>
          </CompareCell>
          <CompareCell>
            <Text>
              Date created:{' '}
              {new Date(metricB.date_created).toLocaleDateString(
                undefined,
                dateOptions
              )}
            </Text>
            <Text>
              Latest update:{' '}
              {new Date(metricB.date_updated).toLocaleDateString(
                undefined,
                dateOptions
              )}
            </Text>
          </CompareCell>
        </CompareSection>
      </CompareRowGrid>
    </Layout>
  )
}
ComparisonPage.propTypes = {
  data: PropTypes.any,
  location: PropTypes.any
}
export default ComparisonPage

export const pageQuery = graphql`
  query ComparisonQuery($directus_id: String!) {
    comparison: comparison(directus_id: { eq: $directus_id }) {
      status
      title
      slug
      description
      directus_id
      metric_a {
        ...MetricFragment
      }
      metric_b {
        ...MetricFragment
      }
    }
  }

  fragment MetricFragment on Metric {
    name
    description
    example
    slug
    formulas {
      formula
    }
    instant_metrics {
      service {
        name
      }
      url
    }
    date_created
    date_updated
    role {
      name
      slug
    }
    category {
      name
      slug
    }
  }
`
