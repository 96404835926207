/**
 *  Cta
 * @author Nicolas Venne
 *
 * @copyright Klipfolio, All rights reserved
 */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '@icons'
import Link from '@atoms/link'
import { parseMarginOrPadding } from '@helpers'

const CtaComponent = ({ className, link, href, children }) => {
  return (
    <div className={className}>
      <Link to={link} href={href}>
        {children} <Icon size={0.75} name="arrow-right" />
      </Link>
    </div>
  )
}

CtaComponent.propTypes = {
  link: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
}

const CTA = styled(CtaComponent)`
  margin: ${({ margin }) => parseMarginOrPadding(margin)};
  padding: ${({ padding }) => parseMarginOrPadding(padding)};
  ${Link} {
    display: flex;
    align-items: center;
  }

  ${Icon} {
    transition: all 200ms ease-out;

    margin-left: 1rem;
    fill: ${(props) => props.theme.colors.primary};
  }
  :hover {
    ${Icon} {
      fill: ${(props) => props.theme.palette.primary['500']};
      transform: translateX(0.25rem);
    }
  }
`

export default CTA
